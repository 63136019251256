import {FormatDataTable} from '../formatDataTable';

/**
 *
 * @param formatObj
 * @param item
 * @param data
 * @returns {[undefined,string,*,(string|*),(string|*)]}
 * @constructor
 */
export class DataTableOnLoad extends FormatDataTable{

    constructor(item, data) {

        super()

        let self = this;

        let dataJson = JSON.parse(item.data);
        let dataText = '';

        $.each(dataJson, function (index, item) {
            dataText += '<b class="text-danger">' + index + ' : </b> ' + item + '<br>';
        });

        return [
            item.id,
            item.notification,
            self.yesNo(item.is_read),
            dataText,
            self.timeAgo(item.created_at),
        ];
    }
}
