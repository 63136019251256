import $ from "jquery";

/**
 *
 * @constructor
 */
export class FormModalOnLoad {

    constructor() {
        this.event()
    }

    event() {
        let self = this;
        $('#js-clear-fedex-cache').on('click', function () {
            let url = $(this).data('url');
            self.deleteImage(url, $(this));
        })

        $('.js-clear-fedex-token').on('click', function () {
            self.clearFedexTokens();
        })
    }

    clearFedexTokens() {
        $.ajax({
            type: 'DELETE',
            url: 'admin/clear-tokens/fedex',
            dataType: 'json',
            cache: false,
            crossDomain: true,
            data: {},
        }).done(function (result) {
            alert('Success');
        }).fail(function (xhr, textStatus, errorThrown) {
            alert('Error');
        });
    }

    deleteImage(url, button) {
        $.ajax({
            type: 'POST',
            url: url,
            dataType: 'json',
            cache: false,
            crossDomain: true,
            data: {},
        }).done(function (result) {
            alert('Success');
        }).fail(function (xhr, textStatus, errorThrown) {
            alert('Error');
        });
    }
}
