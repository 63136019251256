export class OnSuccess {

    constructor(form) {
        this.form = form
    }

    init(data) {

        $('#calculation-results').html('<i class="fas fa-spinner fa-spin text-black"></i>');

        let html = '';
        $.each(data.data[1], function (index, item) {

            let found = index == data.selected[1];

            html += `
                <table class="table table-bordered  ` + (found ? 'table-dark' : 'table-secondary') + `">
                    <tr>
                        <th colspan="2">
                            <img src="storage/img/shipping/` + item.price.shipping_id + `.webp" class="img-thumbnail" style="height:50px;width:auto;float:left;margin-right:15px">
                            <div style="font-size: 1.4em"><b>` + item.dataArray.service_name + `</b></div>
                            <span class="badge ` + (found ? 'badge-danger' : 'badge-primary') + `">` + data.prices[1][index].toFixed(2) + ` USD</span>
                        </th>
                    </tr>
                    <tr>
                        <td class="w-50">
                            List price : ` + (item.price.desi / 100) + ` desi
                        </td>
                        <td>
                           <span class="badge badge-secondary">` + item.price.price + ` ` + item.price.currency + `</span>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <div class="row">
                                <div class="col-6">Fuel Surcharge </div><div class="col-6">: % ` + item.dataArray.fuel_surcharge + `</div>
                                <div class="col-6">Price </div><div class="col-6">: % ` + item.dataArray.price + `</div>
                                <div class="col-6">Net Price </div><div class="col-6">:  ` + item.dataArray.price_net + `</div>
                                <div class="col-6">` + item.price.currency + `\\USD </div><div class="col-6">:  ` + item.coefficient.toFixed(5) + `</div>
                            </div>
                        </td>
                    </tr>
                </table>`;

        });

        $('#calculation-results').html(html);
    }
}
