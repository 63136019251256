export default class AdminSendToAccountant {

    constructor(){

        this.button = '#send-to-accountant';
        this.events();
    }

    events() {

        let self = this;

        $(document).off('click', this.button).on('click', this.button,function () {
            let orderId = $(this).attr('data-order_id');
            self.send(orderId);
        })
    }

    send(orderId) {
        dd(3);
        $.ajax({
            type: 'POST',
            url: '/admin/order-invoice-send-to-accauntant/' + orderId,
            dataType: 'json',
            cache: false,
            crossDomain: true,
            data: {}
        }).done(function () {

            alert('Send');

        }).fail(function (xhr) {

            alert('Console for errors');

            let content = `
                <h5>ERROR</h5>
                <p>` + xhr.responseJSON.error + `</p>`
            console.log(content);

        });
    }
}
