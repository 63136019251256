export class OnSuccess{

    /**
     *
     * @param form
     */
    constructor(form) {
        this.form = form
    }

    /**
     *
     * @param data
     */
    init(data){
        console.log(this.form)
        this.form.form.closest('tr').remove();
    }
}
