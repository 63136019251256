export class OnSuccess {

    constructor(form) {
        this.form = form
    }

    init(data) {

        console.log(data);
        $('form .alert-success').removeClass('d-none').html(data.total + ' bölge güncellendi.');

    }
}
