import {newModal} from '../../newmodal/formModal'
import {OnFail} from "../onFail/vendorUploadFileFailed";

export class OnSuccess {

    constructor(form) {
        this.form = form
        this.modal = new newModal();

        this.updatedColor = '00f';
        this.savedColor = 'f00';
    }

    init(data) {
        this.resetSuccessMessages();

        let onFail = new OnFail;
        onFail.resetErrorMessages();

        let orderListHtml = '<hr><small>Kaydedilen ipariş numaraları : ' + this.formattedOrderList(data.order_list) + '</small>';
        let html = data.total_processed_orders + ' sipariş kaydedildi.' + orderListHtml + this.orderListFooter();
        $('.js-form-success').removeClass('d-none').html(html);
    }

    resetSuccessMessages() {
        $('.js-form-success').addClass('d-none').html('')
    }

    formattedOrderList(list) {
        let formattedArray = [];
        let self = this;

        list.forEach(function (item) {
            let color = self.updatedColor;
            if (item['is_new']) {
                color = self.savedColor;
            }
            formattedArray.push('<span ' + self.styleWithColor(color) + '>' + item['order_id'] + '</span>');
        });

        return formattedArray.join(', ');
    }

    orderListFooter() {
        return `
            <div class="pt-3">
                <small>
                    <div ` + this.styleWithColor(this.updatedColor) + `>
                        <div ` + this.styleWithBgColor(this.updatedColor) + `></div>
                        Güncellenen siparişler.
                    </div>
                    <div ` + this.styleWithColor(this.savedColor) + `>
                        <div ` + this.styleWithBgColor(this.savedColor) + `></div>
                        Yeni siparişler.
                    </div>
                </small>
        </div>`;
    }

    styleWithBgColor(color) {
        return 'style="display:inline-block;width:10px;height:10px;background:#' + color + '"';
    }

    styleWithColor(color) {
        return 'style="color:#' + color + '"';
    }
}
