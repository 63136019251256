/**
 *
 */
export default class New {

    constructor(obj) {
        this.dataObj = obj;
        this.events();
    }

    events() {
        let self = this;
    }

    getContent(order) {

        let err = this.checkErrors(order);
        if (err !== '') {
            return err;
        }

        let countryId = this.dataObj.attr('data-country_id');

        let tableHeader = $('.template #table_header table').html();

        let templateFooter = $('#table-order-child tfoot').html();

        return `
            <div class="text-right pb-2" id="package_top_row_` + order.id + `">
                ` + this.printLabelContent(order) + `
            </div>

            <table
                class="table table-bordered table-sm order_child_table hide_on_success_` + order.id + `"
                id="package_table_` + order.id + `"
                data-country_id="` + countryId + `">

               <thead class="label_msg_container">
                    ` + templateFooter + `
               </thead>
                ` + tableHeader + `
               <tbody>
                    ` + this.packageContent(order.order_packages) + `
               </tbody>

            </table>`;
    }

    /**
     *
     */
    printLabelContent(order) {

        let buttonName = 'Etiket';
        if (order.user.can_be_shipper == 1) {
            buttonName = 'Depoya Al';
        }

        let printLabelContent = $('.template #print-label-content').html();
        let shippingServiceId = this.dataObj.attr('data-shipping_service_id');
        let description = this.dataObj.attr('data-description');

        printLabelContent = printLabelContent.replace('__selected_' + shippingServiceId + '__', ' selected="selected" ');
        printLabelContent = printLabelContent.replace(/__ORDER_ID__/g, order.id);
        printLabelContent = printLabelContent.replace(/__DESCRIPTION__/g, description);
        printLabelContent = printLabelContent.replace(/__BUTTON_NAME__/g, buttonName);

        return printLabelContent;
    }

    checkErrors(order) {
        if (this.dataObj.attr('data-is_labellable') === 'false') {
            return this.dataObj.attr('data-is_labellable_reason');
        }

        if (order.order_packages.length === 0) {
            return 'Package required.';
        }

        return '';
    }

    packageContent(packages) {

        let tableBodyContent = '';

        let template = $('#table-order-child tbody').html();

        $.each(packages, function (index, item) {
            let processedTemplate = template;
            processedTemplate = processedTemplate.replace(/__HEIGHT__/g, item.height ?? '');
            processedTemplate = processedTemplate.replace(/__PAKAGE_ID__/g, item.id);
            processedTemplate = processedTemplate.replace(/__DESCTIPTION__/g, item.description ?? '');
            processedTemplate = processedTemplate.replace(/__WIDTH__/g, item.width ?? '');
            processedTemplate = processedTemplate.replace(/__LENGTH__/g, item.length ?? '');
            processedTemplate = processedTemplate.replace(/__WEIGHT__/g, item.weight ?? '');

            tableBodyContent += processedTemplate;
        });

        return tableBodyContent;
    }

}
