export class Login {

    constructor() {
        this.resetButton();
        this.events();
    }

    events() {
        let self = this;

        $('#send_mfa_token').on('click', function () {
            self.loadingButton();
            self.clicked();
        });
    }

    clicked() {

        let self = this;

        $.ajax({
            type: 'POST',
            url: '/send-mfa-token',
            dataType: 'json',
            cache: false,
            crossDomain: true,
            data: {
                email: $('#email').val()
            },
        }).done(function (result) {

            if (result) {
                self.resetButton();
            }

        }).fail(function (xhr) {

            let json = xhr.responseJSON;
            console.log(json);
            alert(json.message);

            self.resetButton();

        });
    }

    resetButton() {
        $('#send_mfa_token').html('<i class="far fa-paper-plane pe-2"></i> Send Token');
    }

    loadingButton() {
        $('#send_mfa_token').html('<i class="fas fa-circle-notch fa-spin"></i> Sending...');
    }
}
