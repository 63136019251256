export class UploadInvoiceFile {

    constructor(documentTab) {

        let self = this;
        self.documentTab = documentTab;

        $(document).off('click', '#upload-vendor-document-button').on('click', '#upload-vendor-document-button', function () {
            self.upload();
        });
    }

    upload() {

        let self = this;
        let orderId = $('#upload_vendor_document').data('order_id');

        let file = $('#upload_vendor_document').data('base64');
        if( typeof file == 'undefined') {
            alert('File conversion error.');
            return;
        }

        $.ajax({
            type: 'PATCH',
            url: '/vendor-site/orders/' + orderId + '/upload-document',
            dataType: 'json',
            cache: false,
            crossDomain: true,
            data: {
                file: file,
                type: $('#ship_document_type').val()
            },
        }).done(function (data) {

            self.documentTab.showDocuments();
            console.log(data);

        }).fail(function (xhr, textStatus, errorThrown) {

            console.log(errorThrown);

        });
    }

}
