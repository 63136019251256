import {formErrors} from "../../../forms/formErrors";

export default class CreateReturn {

    constructor() {

        this.button = $('.js-create-return');
        let self = this;

        this.button.on('click', function () {
            self.create();
        })
    }

    /**
     *
     */
    create() {
        let self = this;
        self.button.find('.text').html('<i class="fas fa-circle-notch fa-spin"></i>');

        $.ajax({
            type: 'POST',
            url: 'vendor-site/create-return/' + $('#form_order').data('order_id'),
            dataType: 'json',
            cache: false,
            crossDomain: true,
            data: {},
        }).done(function (result) {

            self.button.find('.text').html('Kaydedildi order-id : ' + result.id);

        }).fail(function (xhr) {
            let err =xhr.responseJSON.message;
            alert(err);
            self.button.find('.text').html(err);
        });
    }
}
