import $ from 'jquery';
import {Location} from './vendorOrderModal/location';
import {SelectCity} from '../../selectCity';
import {dataTable} from "../../datatables/dataTable";

/**
 *
 */
export class FormModalOnLoad {

    /**
     *
     */
    constructor() {

        let self = this;

        $(document).off('click', '.js-delete_parent').on('click', '.js-delete_parent', function (e) {
            self.resetErrors();
            let url = $(this).data('url');
            self.destroy(url, $(this));
        });

        let location = new Location('#modal_theme_primary');
        // location.reset(); // Hata veriyordu kaldırıldı

        $('.full_search').removeClass('d-none');
        $('.toggle_full_search').removeClass('d-none');

        new SelectCity();

        //self.adminUserType();

        // Trigger only if reseller tab exists
        if($('#reseller').length > 0 ){
            self.resellerTabSelect2();
            self.parentTabGetChildUserList();
        }

        new dataTable('.userDataTable');
    }

    /**
     * change_location button event
     */
    changeLocationButton() {
        $('#change_location').off('click').on('click', function () {
            $(this).hide();
            $('.full_search').removeClass('d-none');
            $('.toggle_full_search').removeClass('d-none');
        });
    }

    /**
     *
     */
    resellerTabSelect2() {

        let self = this;
        let select = $('.user_select');

        let url = select.data('url');
        let placeholder = select.data('placeholder');

        let options = {
            placeholder: placeholder,
            dropdownParent: $('#modal_theme_primary'),
            minimumInputLength: 1, // only start searching when the user has input 3 or more characters
            ajax: {
                url: url,
                delay: 250, // wait 250 milliseconds before triggering the request
                method: 'GET',
                dataType: 'json',
                data: function (params) {

                    return {
                        start: 0,
                        length: 10,
                        search: {
                            value: params.term,
                            regex: false
                        },
                        type: 'public'
                    };
                },
                processResults: function (data, params) {
                    return {
                        results: data.data
                    };
                }
            },
            escapeMarkup: function (markup) {
                return markup;
            },
            templateResult: function (data) {
                return `
                    <div>
                        <small><b>` + data.full_name + `</b></small>
                        <br>
                        <div class="text-primary">` + data.company_name + `</div>
                    </div>`;
            },
            templateSelection: function (data) {
                return data.text;
            }
        }

        select.css({'width': '100%'}).select2(options);

        select.on('select2:select', function (e) {
            self.addChildUser(e.params.data);
        })
    }

    /**
     *
     */
    addChildUser(data) {

        $('.user_select_errors').addClass('d-none').html('');

        let url = $('.user_select').data('update_url');
        let self = this;
        $.ajax({
            type: 'post',
            url: url,
            dataType: 'json',
            cache: false,
            crossDomain: true,
            data: {
                id : data.id
            },
        }).done(function (result) {
            alert('Eklendi.');
            self.parentTabGetChildUserList();
        }).fail(function (xhr) {
            $('.user_select_errors').removeClass('d-none').html(xhr.responseJSON.message);
        });
    }

    /**
     *
     */
    parentTabGetChildUserList() {
        let url = $('.user_select').data('list_url');
        let self = this;
        $.ajax({
            type: 'POST',
            url: url,
            dataType: 'json',
            cache: false,
            crossDomain: true,
        }).done(function (result) {
            self.createUserTable(result.list);
        }).fail(function (xhr) {
           alert(xhr.responseJSON.error);
        });
    }

    /**
     *
     * @param url
     */
    destroy(url, button) {
        let self = this;

        $.ajax({
            type: 'post',
            url: url,
            dataType: 'json',
            cache: false,
            crossDomain: true,
        }).done(function (result) {
            button.parent().parent().removeClass('row').addClass('text-center').addClass('text-danger').html('Deleted');
        }).fail(function (xhr) {
            self.showErrors(xhr.responseJSON.message);
        });
    }

    /**
     *
     */
    resetErrors(msg) {
        $('.js-parent-erros').html(msg).removeClass('d-none');
    }

    /**
     *
     */
    resetErrors() {
        $('.js-parent-erros').addClass('d-none');
    }

    /**
     *
     * @param list
     */
    createUserTable(list){

        let html = '';
        let template = $('#reseller-template').html();
        let templateRow = $('#reseller-template tbody').html();

        $.each(list, function (index, item) {

            let url = $('.user_select').data('delete_url').replace('###', item.id);
            let row = '';
            row = templateRow.replace(/__ITEM_ID__/g, item.id);
            row = row.replace(/__ITEM_FULL_NAME__/g, item.full_name);
            row = row.replace(/__ACCOUNT_NAME__/g, item.company_name);
            row = row.replace(/__URL__/g, url);

            html += row;
        });
        $('#reseller-template tbody').html(html);

        $('.user_results').html($('#reseller-template').html());
    }
}
