import {FormatDataTable} from '../formatDataTable';

/**
 *
 * @param formatObj
 * @param item
 * @param data
 * @returns {[undefined,string,*,(string|*),(string|*)]}
 * @constructor
 */
export class DataTableOnLoad extends FormatDataTable {

    constructor(item, data) {

        super();

        let self = this;
        let userType = [
            'Kişisel',
            'Kurumsal'
        ];

        let logo = '';
        if (item.logoSrc != '') {
            logo = '<img src="' + item.logoSrc + '" class="user-logo-img" alt="lgoo">';
        }

        return [
            item.id,
            '<img src="' + item.avatar + '" class="img-thumbnail rounded-circle">',
            logo,
            '<b>' + item.full_name + '</b>',
            item.company_name,
            item.email,
            item.permission_name,
            userType[item.user_type],
            `
                <div class="text-center">
                    <a href="` + data.login_url.replace(/###/g, item.id) + `" class="btn btn-circle btn-danger btn-sm js-delete-onclick">
                        <i class="fas fa-sign-in-alt"></i>
                    </a>
                </div>`
        ];
    }
}
