export class Tracker {

    constructor() {

        this.button = $('#track-button');
        this.html = $('#track-button').data('content');
        this.button.html(this.html);
        this.events();
    }

    events() {
        let self = this;

        this.button.on('click', function () {
            self.clicked();
        });
    }

    clicked() {
        let self = this;

        this.button.html('<i class="fas fa-spinner fa-spin m-3"></i>');

        setTimeout(function () {
            $('.transaction-message').html('No shipment was found for the specified tracking id.').removeClass('d-none');

            self.button.html(self.html);
        },2000)
    }

}
