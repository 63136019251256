export class OnSuccess{

    constructor(form) {
        this.form = form
    }

    init(data){
        $('.modal-footer').prepend('<div>Password : <b>' + data.msg + '</b></div>');
    }
}
