import {newModal} from '../../newmodal/formModal'

export class OnSuccess {

    /**
     *
     * @param form
     */
    constructor(form) {
        this.form = form
    }

    /**
     *
     * @param data
     */
    init(data) {

        window.location.href = data.fileName;

    }

}
