import $ from "jquery";

/**
 *
 */
export default class AdminChangeIntegration {

    constructor() {
        this.selectBox = $('#order_change_entegration_id');
        if (this.selectBox.length > 0) {
            this.events();
        }
    }

    events() {

        let self = this;

        this.selectBox.off('change').on('change', function () {
            let url = $(this).attr('data-url');
            let data = {
                new_integration: $(this).find(':selected').val()
            };

            self.changeStatus(data, url);
        })
    }

    /**
     * Hem vendor hem adminde kullanılıyor. url ler ikisinde de farklı, haliyle urlyi önyüzden alınmalı.
     */
    changeStatus(data, url) {

        $.ajax({
            type: 'POST',
            url: url,
            dataType: 'json',
            cache: false,
            crossDomain: true,
            data: data
        }).done(function () {

            alert('Integration changed');

        }).fail(function (xhr) {

            alert('Console for errors');

            let content = `
                <h5>ERROR</h5>
                <p>` + xhr.responseJSON.error + `</p>`
            console.log(content);

        });
    }
}
