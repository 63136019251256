import {FormatDataTable} from '../formatDataTable';

export class DataTableOnLoad extends FormatDataTable {

    constructor(item, data) {

        super();

        let self = this;
        self.item = item;

        return [
            item.id,
            '<b>' + item.path + '</b>',
            item.order_id,
            self.getOrderStatus(),
            self.yesNo(item.is_send),
            self.sendButtom()
        ];
    }

    sendButtom() {
        return !this.item.is_send ? `
                <div class="text-center">
                    <span class="btn btn-circle btn-success btn-sm js-send-document" data-id="` + this.item.order_id + `" role="button">
                        <i class="fas fa-share"></i>
                    </span>
                </div>
            ` : `
                <div class="text-center">
                    <span class="btn btn-circle btn-success btn-sm"  style="opacity: 0.25" title="Already sent before.">
                        <i class="fas fa-share"></i>
                    </span>
                </div>`
    }

    getOrderStatus() {
        let status = this.item.status;
        if (typeof status == 'undefined') {
            return `
                <span class="order_satus" style="background:#ccc;">
                    Undefined
                </span>`;
        }

        return `
            <span class="order_satus" style="background:#` + status.color + `;">
                ` + status.name + `
            </span>`;
    }
}
