import {FormatDataTable} from '../formatDataTable';

/**
 *
 * @param formatObj
 * @param item
 * @param data
 * @returns {[undefined,string,*,(string|*),(string|*)]}
 * @constructor
 */
export class DataTableOnLoad extends FormatDataTable {

    constructor(item, data) {

        super();

        let self = this;
        return [
            item.id,
            '<b>' + item.name + '</b>',
            item.iso3,
            item.iso2,
            item.capital,
            item.currency,
            item.region,
            item.subregion,
            self.yesNo(item.is_accepted),
            self.updateButton(data, item.id)
        ];
    }
}
