import {FormatDataTable} from '../formatDataTable';

/**
 *
 * @param formatObj
 * @param item
 * @param data
 * @returns {[undefined,string,*,(string|*),(string|*)]}
 * @constructor
 */
export class DataTableOnLoad extends FormatDataTable {

    constructor(item, data) {

        super()

        let self = this;

        let countryButton = self.updateButton(data, item.id, 'adminUserGroupPriceCountry');
        countryButton = countryButton.replace(/fas fa-pen/g, "fas fa-tags");
        return [
            item.id,
            '<b>' + item.name + '</b> <br><small>' + item.serviceName + '</small>',
            self.yesNo(item.isExpress),
            item.min,
            item.max,
            item.price + '<small class="pl-1">%</small>',
            item.price_net,
            item.fuel_surcharge + '<small class="pl-1">%</small>',
            countryButton,
            self.deleteButton(item),
        ];
    }
}
