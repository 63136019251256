import {FormatDataTable} from '../formatDataTable';

/**
 *
 * @param formatObj
 * @param item
 * @param data
 * @returns {[undefined,string,*,(string|*),(string|*)]}
 * @constructor
 */
export class DataTableOnLoad extends FormatDataTable{

    constructor(item, data) {

        super();

        let self = this;

        let fromList = '';
        $.each(item.from, function (index, fromItem) {
            fromList += '<span class="order_satus" style="background:#' + fromItem.color + ';">' + fromItem.name + '</span>';
        });

        let toList = '';
        $.each(item.to, function (index, toItem) {
            toList += '<span class="order_satus" style="background:#' + toItem.color + ';">' + toItem.name + '</span>';
        });
        let statusList = ['Vendor', 'ShipExporgin'];

        return [
            item.id,
            '<b>' + item.name + '</b> ',
            fromList,
            toList,
            `
            <div class="text-center">
                <span class="order_satus" style="background:#` + item.color + `;">
                    #` + item.color + `
                </span>
            </div>`,
            `
            <div class="text-center">
                ` + statusList[item.status_of] + `
            </div>`
        ];
    }
}
