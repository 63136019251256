import {OnSuccess} from "../onSuccess/vendorUploadFileSuccess";

export class OnFail {

    constructor(form) {
        this.form = form
    }

    /**
     *
     * @param xhr
     */
    init(xhr) {
        alert('Error');
    }
}
