import $ from "jquery";

/**
 *
 */
export class ShipmentPrice {

    constructor() {
        this.user = $('#user_data').data('data');
    }

    /**
     * check content => content.find('td').css({'border':'1px solid #f00'});
     */
    showPriceEvent(orderId) {

        let self = this;
        let sourceRow = $('#order_' + orderId);

        self.countryId = sourceRow.data('country_id');
        if (self.countryId == 'undefined') {
            console.log('Ülke adı eksik!');
        }

        self.isReturn = sourceRow.data('is_return');
        self.content = $('#package_table_' + orderId);
        self.topRow = $('#package_top_row_' + orderId);
        let content = self.content;

        let events = 'keyup input paste';

        // Weight button change event
        content.find('input[name^=weight]').off(events).on(events, function () {
            self.getPrices();
        });

        content.find('input[name^=width]').off(events).on(events, function () {
            self.getPrices();
        });

        content.find('input[name^=height]').off(events).on(events, function () {
            self.getPrices();
        });

        content.find('input[name^=length]').off(events).on(events, function () {
            self.getPrices();
        });

        // Close all errors.
        $(document).off('click', '.price-errors .close').on('click', '.price-errors .close', function () {
            $('.price-errors').addClass('d-none');
        })

        content.find('input[name^=weight]').eq(0).trigger('input');
    }

    /**
     *
     */
    getPrices() {
        let self = this;

        self.showPrices('Loading', 'Loading');

        $('.price-errors .close').trigger('click');

        let url = $('#order_table').data('price_url');

        $.ajax({
            type: 'POST',
            url: url,
            dataType: 'json',
            crossDomain: true,
            data: {
                country_id: self.countryId,
                desi: self.calculateDesi(),
                is_return: self.isReturn,
            }
        }).done(function (data) {

            if (self.user.is_billable == 0) {
                self.checkPayment(data);
            }


            $('.js-print-label').prop('disabled', false);

            self.showPrices(data.express, data.economic);

        }).fail(function (xhr) {

            self.showPrices('Error', 'Error');

            $('.js-print-label').prop('disabled', true);

            if (typeof xhr.responseJSON.message != 'undefined') {
                self.showError(xhr.responseJSON.message);
            }

            if (typeof xhr.responseJSON.error != 'undefined') {
                self.showError(xhr.responseJSON.error);
            }

            $('.price-errors').removeClass('d-none');
        });
    }

    /**
     *
     */
    checkPayment(data) {

        let labelButton = $('.js-print-label');
        let userDiv = $('#user_data');
        let total = userDiv.data('total');


        if (userDiv.data('payment_type') === 1) {
            // Peşin
            // console.log('ex : ' + data.express, 'eco : ' + data.economic, 'total : ' + total);

            const priceArr = [data.economic, data.express].filter(function (item) {
                return item !== 0 && item !== '';
            });


            let minPrice = Math.min(priceArr)
            if (total < minPrice) {
                $('.js-payment-buttons').removeClass('d-none').each(function () {
                    let json = JSON.parse($(this).attr('data-data')/*.replace(/'/g, '"')*/);
                    json.total = minPrice;
                    $(this).attr('data-data', JSON.stringify(json)/*.replace(/"/g, "'")*/);
                });

                labelButton.addClass('d-none');
            } else {
                $('.js-payment-buttons').addClass('d-none');
                labelButton.removeClass('d-none');
            }
        } else {
            // Vadeli
            $('.js-payment-buttons').addClass('d-none');
        }
    }

    /**
     *
     */
    calculateDesi() {
        let content = this.content;

        let widths = content.find('input[name^=width]').map(function () {
            return this.value;
        }).toArray();

        let heights = content.find('input[name^=height]').map(function () {
            return this.value;
        }).toArray();

        let lengths = content.find('input[name^=length]').map(function () {
            return this.value;
        }).toArray();

        let weights = content.find('input[name^=weight]').map(function () {
            return this.value;
        }).toArray();

        let totalDesi = 0;
        $.each(widths, function (index, params) {
            let desi = []

            // Volumetric desi
            let volumetricDesi = (widths[index] * heights[index] * lengths[index]) / 5000;
            desi.push(volumetricDesi);

            // Weight
            desi.push(weights[index]);

            totalDesi += Math.max.apply(Math, desi);
        });
        return totalDesi;
    }

    /**
     *
     */
    showPrices(express, economic) {

        let content = this.topRow;

        content.find('.js-economic-service .js-price').html(economic);
        content.find('.js-express-service .js-price').html(express);

        if (economic === '') {
            content.find('.js-economic-service .time').html('Not ready');
            content.find('.js-economic-service .badge-danger').remove();
        }

        if (express === '') {
            content.find('.js-express-service .time').html('Not ready');
            content.find('.js-express-service .badge-danger').remove();
        }
    }

    /**
     *
     */
    showError(msg) {
        $('.price-errors .text-msg').html(msg);
    }

}
