import $ from "jquery";

/**
 *
 */
export class Pickup {

    /**
     *
     */
    constructor() {
        this.events();
    }

    /**
     *
     */
    events() {

        let self = this;

        $('.js-check-pikup-address').on('keyup', function () {
            let count = $(this).val().length;
            let target = $(this).parent().parent().find('.js-show-count');

            if (count > 50) {
                target.css({
                    'color': 'red',
                    'font-weight': 'bold',
                });
            }

            if (count >= 73) {
                count = 73;
                let text = $(this).val().substring(0, 73);
                $(this).val(text);
            }
            target.text(count + '/73');
        });

        // Cancel Pickup
        $('.js-cancel_pickup').off('click').on('click', function () {
            let url = $(this).data('url');

            let cancelInvoice = false;
            if ($('input[name=cancel_invoice]:checked').val() === 1) {
                cancelInvoice = true;
            }

            let data = {
                cancelReason: $('input[name=cancel_reason]').val(),
                reInitiaize: $('input[name=re_initiaize]:checked').val(),
                cancelInvoice: cancelInvoice
            }

            self.destroyPickup(url, data, $(this));
        });

        $('.js_choose_date').off('click').on('click', function () {
            let date = $(this).data('date');
            $(this).parent().parent().find('input[name=shipped_at]').val(date);
            $(this).parent().find('.bg-danger').removeClass('bg-danger text-light');
            $(this).addClass('bg-danger text-light').removeClass('bg-light');
        })

        // Select first pickup date
        if ($('input[name=shipped_at]').val() == '') {
            $('.js_choose_date:first').trigger('click');
        }

        // New Pickup
        $('.js-new_pickup_request').off('click').on('click', function () {

            let data = {
                pickup_addres_id: $('.js-new_pickup_request_id').val(),
                pickup_date: $('input[name=pickup_date]').val()
            }
            let url = $(this).data('url');

            self.newPickup(url, data, $(this));
        });
    }

    /**
     *
     * @param url
     */
    destroyPickup(url, data, button ) {

        let buttonText = button.html();

        function resetButton() {
            button.html(buttonText);
        }

        button.html('<i class="fas fa-circle-notch fa-spin"></i>');

        let self = this;

        self.resetError();

        $.ajax({
            type: 'DELETE',
            url: url,
            dataType: 'json',
            cache: false,
            crossDomain: true,
            data: data
        }).done(function (result) {

            self.onSuccess(`
                <div><b>re_initialized :</b> ` + (result.re_initialized ? 'true' : 'false') + `</div>
                <div><b>invoice_cancelled :</b> ` + result.invoice_cancelled + `</div>
                <div><b>pickup_cancelled :</b> ` + result.pickup_cancelled + `</div>
            `);
            resetButton();

        }).fail(function (xhr) {

            self.showErrors(xhr.responseJSON);
            resetButton();
        });
    }

    /**
     *
     * @param url
     */
    newPickup(url, data, button) {

        let buttonText = button.html();

        function resetButton() {
            button.html(buttonText);
        }

        button.html('<i class="fas fa-circle-notch fa-spin"></i>');
        let self = this;
        self.resetError();

        $.ajax({
            type: 'PATCH',
            url: url,
            dataType: 'json',
            cache: false,
            crossDomain: true,
            data: data
        }).done(function (result) {

            self.onSuccess('Success : ' + result);
            resetButton();

        }).fail(function (xhr) {

            self.showErrors(xhr.responseJSON);
            resetButton();

        });

    }

    /**
     *
     * @param message
     */
    onSuccess(message) {

        let cancelPickupDiv = $('#cancel_pickup');

        cancelPickupDiv.prepend(`
            <div class="succress_message alert alert-success">` + message + `</div>
        `);
        cancelPickupDiv.attr('disabled', 'true');
    }

    /**
     *
     * @param json
     */
    showErrors(json) {

        let message = '';
        if (json.hasOwnProperty('error')) {
            message = json.error;
        }
        if (json.hasOwnProperty('message')) {
            message = json.message;
        }
        let content = `
                <h5>ERROR</h5>
                <p>` + message + `</p>`
        $('.cancellation_error').html(content).removeClass('d-none');
    }

    /**
     *
     */
    resetError() {
        $('.succress_message').remove();
        $('.cancellation_error').html('').addClass('d-none');
    }
}
