import {OrderAddLocation} from '../../../../pages/orderAddLocation';

/**
 * Order Modalı içinde şehir ülke seçimi ile ilgili işlemler.
 */
export class Location {

    /**
     *
     * @param parentId
     */
    constructor(parentId) {

        this.partialSearch = [];

        /**
         * Select2 modal üzerinde düzgün çalışması için gerekli.
         *
         * @type {*|jQuery|HTMLElement}
         */
        this.dropdownParent = $(parentId);

        this.createPartialSearch();

        new OrderAddLocation(this);

        // this.addInsertButtons();
    }

    /**
     *
     */
    showOnlyCountry() {
        $('.partial_search').show();
        this.toogled = false;
    }

    /**
     *
     * @param data['country_name']
     * @param data['country_id']
     */
    partialAddOptionToCoutry(data) {
        let newOption = new Option(data.country_name, data.country_id, false, false);
        $('.partial_search select[name=country_id]').empty().append(newOption).trigger('change');
    }

    /**
     *
     * @param data['state_name']
     * @param data['state_id']
     */
    partialAddOptionToState(data) {
        let newOption = new Option(data.state_name, data.state_id, false, false);
        $('.partial_search select[name=state_id]').empty().append(newOption).trigger('change');
    }

    /**
     *
     * @param data['city_name']
     * @param data['id']
     */
    partialAddOptionToCity(data) {
        let newOption = new Option(data.city_name, data.id, false, false);
        $('.partial_search select[name=city_id]').empty().append(newOption); // Do not trigger this time -> .trigger('change')

    }

    /**
     * TODO : Burası yarım kalmış
     *
     * @param country
     * @returns {*|jQuery|HTMLElement}
     */
    formatCountry(country) {

        let $country = $(
            '<span class="flag-icon flag-icon-asd flag-icon-squared">Loading</span>' +
            '<span class="flag-text">Loading</span>'
        );

        return $country;
    };

    /**
     * Creates partial selection select2
     */
    createPartialSearch() {
        let self = this;
        let country = $('.partial_search select[name=country_id]');
        let state = $('.partial_search select[name=state_id]');
        let city = $('.partial_search select[name=city_id]');

        // self.createPartialSelect2(country, null, city);

        country.css({'width': '100%'}).select2({
            placeholder: 'Select a country',
            dropdownParent: self.dropdownParent,
            escapeMarkup: function (markup) {
                return markup;
            },
            templateResult: function (data) {
                let iso2 = $(data.element).data('iso2');
                let string = data.text;
                if (typeof iso2 != 'undefined') {
                    string = '<img src="/img/country-icons-24px/' + iso2 + '.png" style="width:25px;border:1px solid #999;margin-right:15px"> ' + data.text;
                }
                return string;
            }
        });

        country.on('select2:open', (e) => {
            $('.select2-new-item').remove()
        });

        self.createPartialSelect2(state, country, null);

        self.createPartialSelect2(city, state, null);

        country.on('select2:select', function (e) {
            state.empty();
            city.empty();
        });

        state.on('select2:select', function (e) {
            city.empty();
        });

        city.on('select2:select', function (e) {
        });
    }

    /**
     * Creates select2
     *
     * @param item
     * @param targetItem
     * @param resetItem
     */
    createPartialSelect2(item, targetItem, resetItem) {

        let self = this;

        item.css({'width': '100%'});
        let placeholder = item.data('placeholder');
        let url = item.data('url');
        let langCode = item.data('lang_code');

        let options = {
            placeholder: placeholder,
            dropdownParent: self.dropdownParent,
            minimumInputLength: 1, // only start searching when the user has input 3 or more characters
            ajax: {
                url: url,
                delay: 250, // wait 250 milliseconds before triggering the request
                method: 'post',
                dataType: 'json',
                data: function (params) {

                    let targetVal = null;
                    if (targetItem != null) {
                        targetVal = targetItem.select2('data')[0];
                        targetVal = targetVal.id;
                    }

                    return {
                        search: params.term,
                        type: 'public',
                        country_id: targetVal,
                        state_id: targetVal,
                        lang_code: langCode
                    };
                },
                processResults: function (data, params) {
                    let res = {
                        results: data.results
                    }

                    if (resetItem != null) {
                        resetItem.empty();
                    }

                    return res;
                }
            },
            escapeMarkup: function (markup) {
                return markup;
            },
            templateResult: function (data) {
                return data.html;
            },
            templateSelection: function (data) {
                return data.text;
            }
        }

        let select2 = item.select2(options);

        // Add "Create new item" button
        select2.on('select2:open', (e) => {

            $('.select2-new-item').remove()
            let resultDiv = $('.select2-results');
            switch (item.attr('name')) {
                case 'country_id':
                    break
                case 'state_id':
                    resultDiv.append(`
                        <small class="select2-new-item state">
                            <i class="fas fa-plus-circle"></i> <b>Create new item</b>
                        </small>`)
                    break
                case 'city_id':
                    resultDiv.append(`
                        <small class="select2-new-item city">
                            <i class="fas fa-plus-circle"></i> <b>Create new item</b>
                        </small>`)
                    break
            }
        });

        self.partialSearch.push(select2)

    }

    reset() {

    }

}
