import {FormatDataTable} from '../formatDataTable';

/**
 *
 * @param formatObj
 * @param item
 * @param data
 * @returns {[undefined,string,*,(string|*),(string|*)]}
 * @constructor
 */
export class DataTableOnLoad extends FormatDataTable{

    constructor(item, data) {

        super()

        let self = this;

        return [
            item.id,
            item.variable,
            item.value,
            self.updateButton(data, item.id),
            self.deleteButton(item),
        ];
    }
}
