import {FormatDataTable} from '../formatDataTable';

/**
 *
 * @param formatObj
 * @param item
 * @param data
 * @returns {[undefined,string,*,(string|*),(string|*)]}
 * @constructor
 */
export class DataTableOnLoad extends FormatDataTable {

    constructor(item, data) {

        super();

        let self = this;

        let className = 'btn-info ';
        let cacheText = 'Has cache';
        if (!item.hasCache) {
            className = 'btn-secondary';
            cacheText = 'Not cached yet';
        }
        return [
            item.id,
            '<b>' + item.name + '</b>',
            '<div class="text-center">' +item.total+ '</div>',
            `<div class="text-center">
                <button
                    type="button"
                    class="btn hasTooltip ` + className + ` btn-circle btn-sm js-delete-cache"
                    data-id="` + item.id + `"
                    data-tooltip="tooltip"
                    data-html="true"
                    data-placement="right"
                    title="` + cacheText + `">
                    <i class="fas fa-shower"></i>
                </button>
            </div>`,
            self.updateButton(data, item.id, 'adminUserGroup'),
            self.deleteButton(item),
        ];
    }
}
