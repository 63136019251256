export class OnSuccess{

    constructor(form) {
        this.form = form
    }

    init(data){
        console.log(data);

        $('.main_button')
            .prop('disabled', false)
            .removeClass('btn-primary')
            .addClass('btn-success')
            .html('Billed');
    }
}
