import {FormatDataTable} from '../formatDataTable';

/**
 *
 * @param formatObj
 * @param item
 * @param data
 * @returns {[undefined,string,*,(string|*),(string|*)]}
 * @constructor
 */
export class DataTableOnLoad extends FormatDataTable {

    constructor(item) {

        super();

        return [
            item.id,
            item.full_name,
            item.company_name,
            item.city_name,

            `<div class="text-center js-address-toggle-visiblity" data-address_id="` + item.id + `">
                <span class="btn ` + (item.is_visible ? 'btn-success' : 'btn-warning') + ` btn-circle btn-sm">
                    <i class="far ` + (item.is_visible ? 'fa-eye' : 'fa-eye-slash') + `"></i>
                </span>
            </div>`
        ];
    }
}
