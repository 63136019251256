import $ from "jquery";

export class CalculatePackageSize {

    /**
     *
     */
    constructor() {
        this.events();
        // this.calculate();
    }

    /**
     *
     */
    events() {
        let self = this;

        // INPUTS
        $(document).off('keyup', '.js-recalculate_package_size').on('keyup', '.js-recalculate_package_size', function () {
            self.calculate();
        });
    }

    /**
     *
     */
    calculate() {

        let self = this;
        $('.form_order_table').each(function () {

            let quantity = [];
            $(this).find('input[name^=quantity]').each(function () {
                let val = parseInt($(this).val());
                // Nan values must be inserted
                if (isNaN(val)) {
                    quantity.push(0);
                } else {
                    quantity.push(val);
                }
            });

            // WEIGHT
            let weight = 0;
            $(this).find('input[name^=product_weight]').each(function (index) {
                let val = parseFloat($(this).val());
                if (!isNaN(val)) {
                    weight += val * quantity[index];
                }
            });

            $(this).find('input[name^=package_weight]').val(weight);
        })
    }

    /**
     * @param array
     */
    maxValues(array) {
        const newArray = array.filter(function (value) {
            return !Number.isNaN(value);
        });

        return Math.max(...newArray);
    }

}
