import {formErrors} from './formErrors.js'

/**
 *
 */
export class FormFileReader {

    constructor(AjaxForm) {
        this.AjaxForm = AjaxForm;
        this.max_size = 100000;
        this.init();
    }

    maxFileSize() {
        if (typeof (this.AjaxForm.form.find('input[name=MAX_FILE_SIZE]').val()) != 'undefined') {
            this.max_size = this.AjaxForm.form.find('input[name=MAX_FILE_SIZE]').val() / 1000;
        }
        return this.max_size;
    }

    init() {

        let self = this;
        if (typeof self.AjaxForm != 'undefined') {

            let fileInput = self.AjaxForm.form.find('input[type=file]');

            self.name = fileInput.attr('name')

            fileInput.on('change', function (e) {

                let error = new formErrors({'n': 'n'}, self.AjaxForm.form);
                error.resetFormStyle()
                self.files = e.target.files;
                $(this).attr('data-base64', '');
                self.onChange($(this));
            })
        }
    }

    onChange(target) {
        target.attr('data-base64', '');

        let self = this;

        let fileTypes = [
            'image/jpeg',
            'image/jpg',
            'image/png',
            'image/webp',
            'image/gif',
            'application/pdf',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-excel'
        ];

        if (!fileTypes.includes(self.files[0].type)) {
            self.showErrors(self.name, 'File type error.( ' + self.files[0].type + ' )');
            return;
        }

        self.AjaxForm.disableButton('Dosya hazırlanıyor.');

        if (!(self.files && self.files[0])) {
            self.showErrors(self.name, 'Dosya ekleyin.');
            return;
        }

        let FR = new FileReader();

        let size = self.files[0].size / 1024 / 1024;
        size = Math.round(size * 100) / 100;

        if (size > self.maxFileSize()) {
            self.showErrors(self.name, 'Size error : ' + size + ' > ' + self.maxFileSize() + 'kb');
            return;
        }

        FR.addEventListener('load', function (e) {
            console.log(e.target.result.split(';')[0])
            let ext = e.target.result.split(';')[0].match(/jpeg|jpg|png|gif|webp|xml|application\/pdf|octet-stream|data:application\/vnd.ms-excel/)[0].trim();
            const excel = 'data:application/vnd.ms-excel';
            if (ext == 'jpg' || ext == 'jpeg' || ext == 'png' || ext == 'gif' || ext == 'webp' || ext == 'xml' || ext == 'octet-stream' || ext == 'application/pdf' || ext == excel) {
                target.attr('data-base64', e.target.result);

                self.resetImage(target);

                if (ext != 'xml' && ext != 'octet-stream') {
                    self.showImage(e.target.result, target);
                }

                target.parent().find('label').css({
                    'color': 'Green'
                }).text('Choosed.');

                self.AjaxForm.enableButton();
            } else {
                self.showErrors(self.name, 'File format error!');
            }
        });

        FR.readAsDataURL(self.files[0]);
    }

    showErrors(name, err) {
        let obj = {};
        obj[name] = err;
        new formErrors(obj, this.AjaxForm.form);
        $(this).val('');
    }

    resetImage(target) {
        target.parent().find('.js-file-image').remove();
        target.parent().find('label').css({
            'margin-left': '0px'
        }).text('Choose File...');
    }

    showImage(src, target) {
        target.parent().find('label').css({
            'margin-left': '110px'
        });
        target.before(`
            <span class="js-file-image">
                <img  src="` + src + `" style="width:100px;margin-right:10px" alt="">
            </span>
        `);
    }

}
