import {newModal} from '../../newmodal/formModal'

export class OnSuccess{

    constructor(form) {
        this.form = form

        this.modal = new newModal()
    }

    init(data){
        this.modal.close();
    }
}
