import {FormatDataTable} from '../formatDataTable';

export class DataTableOnLoad extends FormatDataTable {

    constructor(item, data) {

        super();

        return [
            item.id,
            item.full_name,
            item.company_name,
            item.city_name,
            this.getType(item.user, item.id),
            this.updateButton(data, item.id, 'vendorAddressModal'),
            this.deleteButton(item),
        ];
    }

    getType(user, addressId) {
        let type = '';

        if (user.invoice_address_id === addressId) {
            type += `<span class="badge badge-pill badge-danger m-1">invoice</span>`;
        }
        if (user.warehouse_address_id === addressId) {
            type += `<span class="badge badge-pill badge-danger m-1">warehouse</span>`;
        }

        if (user.return_address_id === addressId) {
            type += `<span class="badge badge-pill badge-danger m-1">return</span>`;
        }

        if (user.company_address_id === addressId) {
            type += `<span class="badge badge-pill badge-danger m-1">company</span>`;
        }

        return type;
    }
}
