import {TimeAgoObj} from "../timeago";

export class FormatDataTable {

    constructor() {
        this.time_ago = new TimeAgoObj();
        let self = this;

        $(document).off('click', '.js-delete-onclick').on('click', '.js-delete-onclick', function () {
            let url = $(this).data('url')
            self.deleteItem(url, $(this))
        })
    }

    /**
     * time ago function
     */
    timeAgo(date) {
        if (typeof date == 'undefined') {
            return '-';
        }
        return this.time_ago.showUnixTimeStamp(date);
    }

    ImageColumn(item) {
        let count_image = '';
        if (item.img.count != 0) {
            count_image = '<span class="badge badge-danger navbar-badge">' + item.img.count + '</span>';
        }

        return `
        <div class="open_form text-center"  data-target="image"
            data-id="` + item.id + `"
            data-onload="formImages"
            data-data="` + item.img.entity + `">
            <div class="position-relative hasBgImg tableImgDiv">
                <picture>
                <img src="` + item.img.src + `" alt="data table img">
                </picture>
                ` + count_image + `
            </div>
        </div>`;
    }

    deleteButton(item) {
        if (item.deletable) {
            return `
                <div class="text-center">
                    <span class="btn btn-circle btn-danger btn-sm js-delete-onclick" data-url="` + item.DestroyUrl + `" role="button">
                        <i class="fas fa-trash"></i>
                    </span>
                </div>`;
        } else {
            return this.disabledButton('fa-trash', item.deletableMsg);
        }
    }

    createDeleteButton(deletable, url, msg = 'Undefined') {
        if (deletable) {
            return `
                <div class="text-center">
                    <span class="btn btn-circle btn-danger btn-sm js-delete-onclick" data-url="` + url + `" role="button">
                        <i class="fas fa-trash"></i>
                    </span>
                </div>`;
        } else {
            return this.disabledButton('fa-trash', msg);
        }
    }

    deleteItem(url, button) {

        button.html('<i class="fas fa-spinner fa-pulse"></i>');

        $.ajax({
            type: 'DELETE',
            url: url,
            dataType: 'json',
            cache: false,
            async: false,
            data: {},
            success: function (result) {
                button.closest('tr').remove()
            },
            error: function (xhr, ajaxOptions, thrownError) {
                $("#form").html('ajax_error' + xhr.status + thrownError);
                return false;
            }
        });
    }

    updateButton(data, id, onloadFunctionName = '') {

        if (typeof data.update_url == 'undefined') {
            console.log('update_url parameter required ' + this.constructor.name);
            return;
        }
        let url = data.update_url.replace('###', id)

        if (onloadFunctionName != '') {
            onloadFunctionName = ' data-onload="' + onloadFunctionName + '" ';
        }

        let dataString = JSON.stringify(data);
        dataString = btoa(dataString);

        return `
            <div class="text-center">
                <span class="btn btn-success btn-circle btn-sm open-modal" data-url="` + url + `" ` + onloadFunctionName + ` data-data="` + dataString + `">
                    <i class="fas fa-pen"></i>
                </span>
            </div>`
    }

    disabledButton(icon = 'fa-question', title = 'Disabled') {
        return `
            <div class="text-center hasTooltip"  data-tooltip="tooltip" title="` + title + `">
                <a class="btn btn-circle btn-sm btn-light" disabled="disable" role="button">
                    <i class="fas ` + icon + ` text-gray-400"></i>
                </a>
            </div>`;
    }

    activeButton(item) {
        let active = '<span class="badge badge-success">Active</span>';
        if (item.active == 0) {
            active = '<span class="badge badge-danger">Passive</span>';
        }
        return '<div class="text-center">' + active + '</div>';
    }

    yesNo(item) {
        let active = '<span class="badge badge-success">Yes</span>';
        if (item == 0) {
            active = '<span class="badge badge-danger">No</span>';
        }
        return '<div class="text-center"><div class="text-center">' + active + '</div></div>';
    }

    /**
     *
     */
    isActive(status) {
        let isActive = '<span class="badge badge-success">Yes</span>';

        if (status == 0) {
            isActive = '<span class="badge badge-danger">No</span>';
        }
        return `
            <div class="text-center">
                <div class="text-center">
                    ` + isActive + `
                </div>
            </div>`;
    }

    /**
     *
     */
    checkButton(item) {
        let active = '<span class="badge badge-success">True</span>';
        if (item == 0) {
            active = '<span class="badge badge-danger">False</span>';
        }
        return '<div class="text-center">' + active + '</div>';
    }
}
