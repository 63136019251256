import {CalculateTotals} from './calculateTotals';
import {CalculatePackageSize} from './calculatePackageSize';
import $ from "jquery";

/**
 * Order Modalı içinde product, paket ekleme çıkarma gibi işlemler
 */
export class OrganizePackageContent {

    /**
     *
     */
    constructor() {

        // Total price
        this.calculateTotals = new CalculateTotals();
        this.calculatePackageSize = new CalculatePackageSize();

        this.productCount = 0;

        this.events();

        this.afterTableAdded();
    }

    /**
     *
     */
    events() {

        let self = this;

        $('.number_packages').click(function () {
        
            $('.main_button').trigger('click');
        });

        // DELETE PRODUCT
        $(document).off('click', '#form_order .delete_row').on('click', '#form_order .delete_row', function () {
            $(this).parent().parent().remove();
            self.afterTableAdded();
        });

        // ADD PRODUCT
        $(document).off('click', '#form_order .add_product').on('click', '#form_order .add_product', function () {
            let body = $(this).parent().parent().parent().parent().find('tbody');
            let productDivContent = $("#package_div_content tbody").html();
            productDivContent = productDivContent.replace(/#COUNT#/g, self.productCount);
            body.append(productDivContent);

            self.afterTableAdded();

            self.productCount++;
        });

        // ADD PACKAGE
        $(document).off('click', '.add_package').on('click', '.add_package', function () {

            let packageDivContent = $('#template_div #package_div_content').html();
            let thead = $('.form_order_table thead').html();
            let tfoot = $('#template_div #template_package').html();

            packageDivContent = packageDivContent.replace(/<\/thead>/g, thead + '</thead>');
            packageDivContent = packageDivContent.replace(/<tfoot><\/tfoot>/g, '<tfoot>' + tfoot + '</tfoot>');

            // count
            packageDivContent = packageDivContent.replace(/#COUNT#/g, self.productCount);
            self.productCount++;

            $('#form_order #packages_container').append(packageDivContent);

            self.afterTableAdded();
        });

        // TYPE SELECT
        $(document).off('change', 'select[name^=type]').on('change', 'select[name^=type]', function () {
            let currentTr = $(this).parent().parent();

            if ($(this).val() == 0) {
                currentTr.find('.d-none').removeClass('d-none');
                currentTr.find('input[name^=quantity]').val('');
            } else {
                currentTr.find('.show_by_type').addClass('d-none').val('');
                currentTr.find('input[name^=quantity]').val('1');
            }

            self.calculatePackageSize.calculate();
        });

        // Onload hide cargo dimensions
        $('.form_order_table tr').each(function (index, item) {

            if ($(item).find('select[name^=type]').val() != 0) {
                $(item).find('.show_by_type').addClass('d-none').val('');
                $(item).find('input[name^=quantity]').val('1');
            }

        })

    }

    /**
     * A table or Package added
     */
    afterTableAdded() {
        this.createSortableRows();
        this.onChange();
    }

    /**
     *
     */
    createSortableRows() {
        let self = this;
        $('tbody.sortable_tbody').sortable({
            revert: true,
            connectWith: 'tbody.sortable_tbody',
            stop: function (event, ui) {
                self.onChange();
            },
            update: function () {
                self.calculateTotals.calculate();
                self.calculatePackageSize.calculate();
            }
        });
    }

    /**
     * On anything in modal changes
     */
    onChange() {
        this.countAndSetProductsInPackage();
        this.setEmptyTableHeight();
        this.removeEmptyPackages();
    }

    /**
     * Empty packages must be removed
     */
    removeEmptyPackages() {
        $.each($('.table.package'), function (index, item) {
            if ($(item).find('select[name^=type]').length === 0) {
                $(item).remove();
            }
        });
    }

    /**
     * Counts products in package and sets in input
     */
    countAndSetProductsInPackage() {
        let packageListText = [];
        $('#packages_container .package').each(function () {
            let length = $(this).find('tbody tr').length;
            packageListText.push(length);
        })

        $('input[name=package_list]').val(packageListText);
    }

    /**
     * tbody will be fully closed if there isnt any tr in it.
     * So we add some height to work properly...
     */
    setEmptyTableHeight() {

        $('table').css('border-collapse', 'separate');
        let firstTrHeight = $('.ui-sortable-handle').height();

        $('tbody.sortable_tbody').each(function () {
            if ($(this).find('tr').length === 0) {
                $(this).css('height', firstTrHeight);
            } else {
                $(this).css('height', 'inherit');
            }
        });
    }

}
