import $ from "jquery";

export class FormModalOnLoad {

    constructor() {
        this.installments = [];
        this.events();

        this.validMessage = '';
    }

    events() {
        let self = this;

        // Card number change
        $(document).off('keyup', 'input[name=card_number]').on('keyup', 'input[name=card_number]', function () {
            self.getInstallments();
        });

        $(document).off('keyup, paste, input', 'input[name=total_payment]').on('keyup, paste, input', 'input[name=total_payment]', function () {
            self.getInstallments();
        });

        // Click installment
        $(document).off('click', '#installment_container .installment').on('click', '#installment_container .installment', function () {
            self.unselectAllInstallments();
            self.selectInstallment($(this));
            self.enablePayButton();
        });

        $(document).on('keyup', 'input[name=card_number]', function () {
            self.enablePayButton();
        });

        $(document).off('keyup', 'input[name=card_name]').on('keyup', 'input[name=card_name]', function () {
            self.enablePayButton();
        });

        $(document).off('keyup', 'input[name=card_cvv]').on('keyup', 'input[name=card_cvv]', function () {
            self.enablePayButton();
        });

        /**
         * Dikkat : Tüm installmentlar baştan oluşacak. Ancak taksit seçili ise değer değişmemeli.
         */
        $(document).off('keyup', 'input[name=total_payment]').on('keyup', 'input[name=total_payment]', function () {
            let selectedValue = $("input[name=installment]:checked").val();
            self.showInstallments();
            let selectedInstallment = $("input[name=installment][value=" + selectedValue + "]").parent().parent().parent();
            self.selectInstallment(selectedInstallment);
            self.enablePayButton();
        });

        $(document).off('click', '.disable_on_start').on('click', '.disable_on_start', function () {
            self.onSubmit();
        });
    }

    /**
     *
     */
    onSubmit() {

        let url = $("#form_payment").attr('action');

        let data = {
            card_number: $("input[name=card_number]").val().replace(/\D/g, ''),
            card_name: $("input[name=card_name]").val(),
            card_cvv: $("input[name=card_cvv]").val(),
            total_payment: $("input[name=total_payment]").val(),
            installment: $("input[name=installment]:checked").val(),
            expiry_month: $("select[name=expiry_month]").val(),
            expiry_year: $("select[name=expiry_year]").val(),
            card_type: $("input[name=card_type]").val(),
            payment_id: $("input[name=payment_id]").val(),
        };

        $.ajax({
            type: 'post',
            url: url,
            dataType: 'json',
            cache: false,
            crossDomain: true,
            data: data,
        }).done(function (result) {

            $("#payment_form_container").html(result.form);
            $("#payment_form").submit();

        }).fail(function (xhr) {

            console.log(xhr.responseJSON);

        });
    }

    /**
     *
     */
    prepareForinstallment() {

        $('.js-payment-errors').addClass('d-none');

        $('#installment_container').html('');

        $("#installment_container").html(`
            <div class="d-flex justify-content-center">
                <div class="spinner-border m-5" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        `);
    }

    canGetInstallment() {
        let cardNumber = $('input[name=card_number]').val().replace(/\D/g, '');
        if (cardNumber.length < 15) {
            console.log('cardNumber.length')
            return false;
        }

        if ($('#total_payment').val() == '') {
            console.log('$(\'#total_payment\').val()')
            return false;
        }

        return true;
    }

    /**
     *
     */
    getInstallments() {
        let self = this;

        if (!self.canGetInstallment()) {
            return;
        }

        self.prepareForinstallment();

        let paymentId = $("input[name=payment_id]").val();

        /**
         * routelar çıkartıldı aşağısı test edilmedi.
         * let url = self.routes.get('vendor.payments.get_installments', {'payment_id': paymentId});
         */
        let url = '/vendor-site/payments/' + paymentId + '/payment_installments';

        $.ajax({
            type: 'post',
            url: url,
            dataType: 'json',
            cache: false,
            crossDomain: true,
            data: {
                card_number: $("input[name=card_number]").val().replace(/\D/g, ''),
                total: $('#total_payment').val()
            }
        }).done(function (result) {

            $('input[name=card_type]').val(result.brand);

            self.installments = result.installments;
            self.showInstallments();
            self.selectInstallmentRow(2);

            self.enablePayButton();

            return true;

        }).fail(function (xhr) {

            $('.js-payment-errors').html(xhr.responseJSON.error).removeClass('d-none');

        });
    }

    /**
     *
     */
    showInstallments() {

        let self = this;
        let html = `
            <div class="row bg-success text-black">
                <div class="col-3">

                </div>
                <div class="col-3">
                    <b>Oran</b>
                </div>
                 <div class="col-3">
                    <b>Toplam</b>
                </div>
                <div class="col-3">
                   <b>Aylık</b>
                </div>
            </div>
        `;

        $.each(self.installments, function (installment, item) {
            html += self.installmentRow(installment, item);
        });

        $("#installment_container").html(html);

    }

    installmentRow(installment, item) {
        console.log(item)

        return `
            <div class="installment row">
                <div class="col-3">
                    <div class="dd-none">
                        <input type="radio" value="` + installment + `" name="installment">
                    </div>
                    ` + installment + ` Taksit :
                </div>
                <div class="col-3">
                    ` + item.rate.toFixed(2) + `
                </div>
                 <div class="col-3">
                    ` + item.total.toFixed(2) + ` USD
                </div>
                <div class="col-3">
                    ` + item.monthly.toFixed(2) + ` USD
                </div>
                <div class="col-12">
                    <div class="is_selected d-none bg-success text-black w-25 rounded p-1 text-center">
                        <i class="fas fa-check"></i> Selected
                    </div>
                </div>
            </div>`;
    }

    /**
     * Check first installment
     */
    selectInstallmentRow(row) {
        $("#installment_container .installment:nth-child(" + row + ")").trigger('click');
    }

    /**
     *
     */
    unselectAllInstallments() {
        $('#installment_container .installment').removeClass('dark');
        $('#installment_container .installment .is_selected').addClass('d-none');
    }

    /**
     *
     * @param item ->  .installment element
     */
    selectInstallment(item) {
        item.find('input[name=installment]').attr('checked', true);
        item.addClass('dark');
        item.find('.is_selected').removeClass('d-none');
    }

    /**
     * Checks if valid or not and enables button
     */
    isValid() {
        let parameters = ['total_payment', 'installment', 'card_number', 'card_name', 'card_cvv'];
        let self = this;
        $.each(parameters, function (index, val) {
            if (typeof $("input[name=" + val + "]").val() == 'undefined') {
                self.validMessage = 'Not defined : ' + val;
                return false
            }
        });
        let totalPayment = parseFloat($("input[name=total_payment]").val());

        if (totalPayment == 0 || isNaN(totalPayment)) {
            self.validMessage = 'Toplam ödeme giriniz. ';
            return false;
        }
        if (parseInt($("input[name=installment]").val()) == 0) {
            self.validMessage = 'Taksit miktarı giriniz.';
            return false;
        }
        if ($("input[name=card_number]").val().replace(/\D/g, '').length < 15) {
            self.validMessage = 'Kart numarası giriniz.';
            return false;
        }
        if ($("input[name=card_name]").val().length < 5) {
            self.validMessage = 'Kart sahibi giriniz.';
            return false;
        }
        if ($("input[name=card_cvv]").val().length < 3 || $("input[name=card_cvv]").val().length > 4) {
            self.validMessage = 'Cvv no giriniz.';
            return false;
        }
        return true;
    }

    /**
     *
     */
    enablePayButton() {

        let self = this;
        if (this.isValid()) {
            $(".disable_on_start").attr('disabled', false);
        } else {
            $('.js-payment-errors').html(self.validMessage);
            $(".disable_on_start").attr('disabled', true);
        }

        console.log(self.validMessage);
    }

}
