export class FormModalOnLoad {

    constructor() {
        this.createEvent();
        this.destroyEvent();
    }

    /**
     *
     */
    createEvent() {

        let button = $('.js-shopify-new-shipping');

        button.on('click', function () {

            let url = $(this).data('url');

            button.html('<i class="fas fa-spinner fa-pulse"></i> Servis oluşturuluyor...');

            $.ajax({
                type: 'PATCH',
                url: url,
                dataType: 'json',
                cache: false,
                crossDomain: true,
                data: {},
            }).done(function (result) {

                button.html('<b>' + result.carrier_service.id + '</b> idsi ile yeni servis oluşturuldu.');

                $('.js-shopify-disable-shipping').prop('disabled', false);

                FormModalOnLoad.updateCargoId(result.carrier_service.id)

            }).fail(function (xhr) {

                alert(xhr.responseJSON.message.errors);

            });

        })
    }

    /**
     *
     */
    destroyEvent() {

        let button = $('.js-shopify-disable-shipping');

        button.on('click', function () {
            let buttonDefaultText = button.html();
            button.html('<i class="fas fa-spinner fa-pulse"></i> Servis kaldırılıyor...');

            let url = $(this).data('url');

            $.ajax({
                type: 'DELETE',
                url: url,
                dataType: 'json',
                cache: false,
                crossDomain: true,
                data: {},
            }).done(function (result) {

                button.prop('disabled', true).html(buttonDefaultText);

            }).fail(function (xhr) {

               alert(xhr.responseJSON.message.errors);

            });

        })
    }

    static updateCargoId(newId){
        $("input[name=cargo_id]").val(newId);
    }
}
