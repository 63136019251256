import {OnSuccess} from "../onSuccess/adminUploadFileSuccess";

export class OnFail {

    constructor(form) {
        this.form = form
    }

    init(xhr) {

        this.resetErrorMessages();

        let onSuccess = new OnSuccess();
        onSuccess.resetSuccessMessages();

        if (xhr.status == 403) {
            alert('This action is unauthorized.')
            return
        }

        let jsonErrorList = xhr.responseJSON;

        let errorList = '';
        $.each(jsonErrorList, function (row, item) {

            if (typeof item == 'array') {
                $.each(item, function (key, errors) {
                    errorList += `
                    <div>
                        <b class="text-danger">Satır : ` + row + ` : </b> <b>` + key + ` : </b>` + errors.join() + `
                    </div>`;
                });
            } else {
                errorList += `
                    <div>
                        <b class="text-danger">` + item + `</b>
                    </div>`;
            }
        });

        $('.js-form-errors').removeClass('d-none').html(errorList)
    }

    resetErrorMessages() {
        $('.js-form-errors').addClass('d-none').html('')
    }
}
