import {
    AjaxForms
} from '../forms/ajaxForms'
import $ from "jquery";

/**
 * Revealing Module Pattern
 */
export class newModal {

    /**
     *
     */
    constructor() {
        this.data = {};
        this.init()
    }

    /**
     *
     */
    init() {
        let self = this;
        $(document).off('click', '.open-modal').on('click', '.open-modal', function (e) {
            e.preventDefault();

            self.url = $(this).data('url');
            self.onload = $(this).data('onload');
            self.data = {
                form: $(this).attr('data-target'),
                template: $(this).attr('data-template'),
                id: $(this).attr('data-id'),
                data: $(this).data('data'),
                onload: $(this).data('onload'),
            }

            self.getData();

        });
    }

    /**
     *
     */
    getData() {

        let self = this;
        $.ajax({
            type: 'GET',
            url: self.url,
            dataType: 'json',
            cache: false,
            data: self.data,
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            success: function (result) {

                self.onLoad(result.view);

            },
            error: function (xhr) {

                // dataType = 'text' so responseJSON not exist
                let json = JSON.parse(xhr.responseText);
                self.onError(json.error);

            }
        });
    }

    /**
     *
     */
    onLoad(result) {
        let self = this;
        $("#modal_theme_primary .modal-content").html(result);

        try {

            // Dynamic modal loading is not a good idea
            if (typeof this.onload != 'undefined') {
               // console.log('./onload/' + this.onload + '() loading')
                let {FormModalOnLoad} = require('./onload/' + this.onload);
                new FormModalOnLoad;
            }

        } catch (e) {
            console.error( this.onload,'formModal.js -> ', e.toString(), e);
        }

        new AjaxForms();

        self.openModal()

        fixBootstrapTabs();

        richText();

        select2();

        datePicker();

        dateTimePicker();

        // Bootstrap tooltip
        $('[data-toggle="tooltip"]').tooltip();

        // Fix bs modal
        $('[data-bs-dismiss="modal"]').click(function () {
            $('#modal_theme_primary').modal('toggle');
        })

    }

    /**
     *v
     */
    onError(json) {
        $("#modal_theme_primary .modal-content").html(json);
        this.openModal();
    }

    /**
     *
     */
    openModal() {

        $("#modal_theme_primary").modal("show");

        $(".switch").each(function () {

            let onTxt = $(this).data("on_txt");
            let offTxt = $(this).data("off_txt");

            lc_switch($(this), {
                on_txt: onTxt,
                off_txt: offTxt,
                on_color: 'linear-gradient(140deg, #fabe1c 35%, #f88c21)', // (string) custom "on" color. Supports also gradients
                compact_mode: false
            });
        })

    }

    /**
     *
     */
    close() {
        $("#modal_theme_primary .close").trigger('click');
    }
}
