import {formErrors} from '../formErrors';

export class OnFail {

    /**
     *
     * @param form
     */
    constructor(form) {
        this.form = form;
    }

    /**
     *
     * @param xhr
     */
    init(xhr) {

        if (xhr.status == 403) {
            alert('This action is unauthorized.');
            return;
        }

        let jsonErrorList = xhr.responseJSON;

        // Response may be in errors message bag
        if (typeof jsonErrorList.errors != 'undefined') {
            jsonErrorList = jsonErrorList.errors;
        }

        new formErrors(jsonErrorList, this.form.form);
    }
}
