import {Tracker} from "./app/Tracker";

window._ = require('lodash');

try {
    window.Popper = require('popper.js');

    window.$ = window.jQuery = require('jquery');

    require('bootstrap');

    require('select2');

} catch (e) {
    console.log('/public/js/app.js');
    console.log(e);
}

import {Page} from './admin/pages/appRegister';
import {Login} from "./app/Login";

$(function () {

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    if ($("#app_register").length > 0) {
        new Page;
    }

    const faqs = document.querySelectorAll(".faq");
    faqs.forEach(faq => {
        faq.addEventListener("click", () => {
            faq.classList.toggle("active");
        })
    })

    setHeader();

    new Tracker();

    new Login();

});

$(window).scroll(function () {
    setHeader();
});

function setHeader() {
    if ($(window).scrollTop() == 0) {
        $("header").removeClass("header-scrolled");
        $("header nav").addClass("navbar-dark").removeClass("navbar-light");
        $("#top-logo").attr('src', "img/logo-dark.png");
    } else {
        $("header").addClass("header-scrolled");
        $("header nav").removeClass("navbar-dark").addClass("navbar-light");
        $("#top-logo").attr('src', "img/logo.png");
    }
}
