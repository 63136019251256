export class TrackingLink {

    constructor(allItem) {
        let item = allItem.order;
        this.shippingId = item.shipping_id;
        this.trackingNo = item.tracking_number;
        this.pickupNumber = item.pickup_number;
        if (item.promised_delivery_date != null) {
            let deliveryDate = item.promised_delivery_date.split(' ');
            this.promisdDeliveryDate = deliveryDate[0];
        }
        this.deliveryDate = item.delivery_date;
    }

    show() {

        let pickupNo = this.getPickupNumber();

        let trackingNumber = this.getTrackingNumber();
        return (trackingNumber == '' ? '-' : '<div class="text-center">' + trackingNumber) + `</div>` +
            (pickupNo == '' ? '' : `<hr><div class="text-center"><i class="fas fa-truck-loading"></i> : ` + pickupNo + '</div>') +
            (this.promisdDeliveryDate != null ? ` <hr><div class="text-center text-danger">Tahmini teslimat<br> ` + this.promisdDeliveryDate + `</div>` : '') +
            (this.deliveryDate != null ? ` <hr><div class="text-center text-success">Teslim tarihi<br> ` + this.deliveryDate + `</div>` : '');
    }

    getTrackingNumber() {
        if (this.trackingNo == null) {
            return '';
        }

        if (this.shippingId == null) {
            return '';
        }

        switch (this.shippingId) {
            case 1: // DHL
                return `
                        <a href="https://mydhl.express.dhl/tr/tr/tracking.html#/results?id=` + this.trackingNo + `" target="_blank">
                            ` + this.trackingNo + `
                        </a>`;
                break;
            case 2:// UPS
                return `
                        <a href="https://www.ups.com/track?loc=en_US&requester=QUIC&tracknum=` + this.trackingNo + `/trackdetails" target="_blank">
                            ` + this.trackingNo + `
                        </a>`;
                break;
            case 6:// ASSET
                return `
                        <a href="https://www.assetgli.com/TrackProcess?trackType=0&trackingNumber=` + this.trackingNo + `" target="_blank">
                            ` + this.trackingNo + `
                        </a>`
                break;
            case 7:// FEDEX
                return `
                        <a href="https://www.fedex.com/fedextrack/?trknbr=` + this.trackingNo + `" target="_blank">
                            ` + this.trackingNo + `
                        </a>`;
                break;
            default:
                return ''
        }
    }

    getPickupNumber() {
        let pickUpNo = '';
        switch (this.shippingId) {
            case 1: // DHL
                break;
            case 2:// UPS
                try {
                    let upsPickUpNo = JSON.parse(this.pickupNumber);

                    pickUpNo = `
                            <div class="text-success">PRN :
                                ` + upsPickUpNo.PRN + `
                            </div>`;
                } catch (e) {
                    // Hide json converison errors
                }
                break;
            case 6:// ASSET
                break;
            case 7:// FEDEX

                try {
                    let pickupNumber = JSON.parse(this.pickupNumber);

                    pickUpNo = `
                            <div class="text-success">
                                Pickup No : ` + pickupNumber.Location + pickupNumber.PickupConfirmationNumber + `
                            </div>`;
                } catch (e) {
                    // Hide json converison errors
                }

                break;
            default:
                return ''
        }
        return pickUpNo;
    }
}
