import {FormatDataTable} from '../formatDataTable';

export class DataTableOnLoad extends FormatDataTable{

    constructor(item, data) {

        super();

        let self = this;
        return [
            item.id,
            '<b>' + item.name + '</b>',
           1,
            self.deleteButton(item),
        ];
    }
}
