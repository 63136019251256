import $ from "jquery";
import {Location} from "./vendorOrderModal/location";

/**
 *
 */
export class FormModalOnLoad {

    /**
     *
     */
    constructor() {
        this.parent = $('#modal_theme_primary');

        this.openModal();

        this.events();
    }

    /**
     *
     */
    events() {
        let self = this;

        $("#open-shipping-price").on('click', function () {
            self.openModal();
        });

        $("#get-price").on('click', function () {
            let url = $(this).data("url");
            self.getPriceAjax(url);
        });

        $('.js-change-calculate_desi').on('keyup', function () {
            self.calculateDesi()
        })
    }

    /**
     *
     */
    calculateDesi() {
        let width = this.parent.find('input[name=width]').val();
        let height = this.parent.find('input[name=height]').val();
        let length = this.parent.find('input[name=length]').val();
        let desi = this.parent.find('input[name=weight]').val();
        let desiBySize = width * height * length / 5000;

        console.log('Calculated desi : ' + desiBySize + '-' + desi);

        if (desiBySize > desi) {
            desi = desiBySize
        }

        this.parent.find('input[name=desi]').val(desi);
    }

    /**
     *
     */
    openModal() {

        this.reset();

        let location = new Location('#modal_theme_primary');
        location.showOnlyCountry();

        this.countrySelect();
    }

    /**
     *
     */
    countrySelect() {

        let select = $('#modal_theme_primary .select_country');

        select.css({'width': '100%'}).select2({
            dropdownParent: $('#modal_theme_primary'),
            escapeMarkup: function (markup) {
                return markup;
            },
            templateResult: function (data) {
                let iso2 = $(data.element).data('iso2');
                let string = data.text;
                if (typeof iso2 != 'undefined') {
                    string = '<img src="/img/country-icons-24px/' + iso2 + '.png" style="width:25px;border:1px solid #999;margin-right:15px"> ' + data.text;
                }
                return string;
            }
        });
    }

    /**
     *
     */
    hideResponse() {
        $("#get-price-results").html('').hide();
    }

    /**
     *
     */
    hideErrors() {
        $("#get-price-errors").html('').hide();
    }

    /**
     *
     */
    reset() {
        this.hideErrors();
        this.hideResponse();

        $("#modal_theme_primary input[name=city_id]").val('');

        $("#modal_theme_primary input[name=width]").val('');
        $("#modal_theme_primary input[name=height]").val('');
        $("#modal_theme_primary input[name=length]").val('');
        $("#modal_theme_primary input[name=weight]").val('');
        $("#modal_theme_primary input[name=city_id]").val('');
        $("#modal_theme_primary input[name=post_code]").val('');
        $("#modal_theme_primary select[name=shipment_id]").val('').trigger('click');

        $("#modal_theme_primary .select2").empty();
        $("#modal_theme_primary #toggle_full_search").trigger('click');
    }

    /**
     *
     * @param url
     */
    getPriceAjax(url) {

        let self = this;
        $("#get-price-results").show().html(self.loadingScreen());

        $.ajax({
            type: 'POST',
            url: url,
            dataType: 'json',
            data: {
                order_id: self.order_id,
                desi: $("#modal_theme_primary input[name=desi]").val(),
                country_id: $("#modal_theme_primary select[name=country_id]").val(),
                shipment_id: $("#modal_theme_primary select[name=shipment_id]").val(),
                post_code: $("#modal_theme_primary input[name=post_code]").val(),
                is_return: $("#is_return:checked").val(),
            },
            success: function (response) {
                self.showResults(response);
                $('.save_pdf').text('Created');
            },
            error: function (request) {
                self.showErrors(request.responseJSON.message);
            }
        });
    }

    /**
     *
     */
    showResults(response) {

        this.hideErrors();

        let content = '';
        $.each(response, function (index, item) {
            if (index == 'currency' || index =='desi') {
                return;
            }
            let price = 'Service not defined';
            item = parseFloat(item);
            if (!isNaN(item)) {
                price = parseFloat(item).toFixed(2) + ' <span class="currency">USD</span>';
            }

            content += `
                <div class="row alert alert-success">
                    <div class="col-6 text-right"><b> ` + index + ` </b></div>
                    <div class="col-6 delivery-price"> ` + price + `</div>
                </div>`;
        });

        if (content == '') {
            $("#get-price-errors").html('Empty results').hide();
        } else {
            //content = $("#shipping-price-header-template").html() ;
            $("#get-price-results").html(content).show();
        }
    }

    /**
     *
     */
    showErrors(message) {
        this.hideResponse();
        $("#get-price-errors").html(message).show();
    }

    /**
     *
     * @returns {string}
     */
    loadingScreen() {
        return `
        <div class="loading text-center">
            <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>`;
    }

}
