import {formErrors} from "../../forms/formErrors";

export class FormModalOnLoad {

    constructor() {
        let self = this;
        $("select[name=country_id]").on('change', function () {
            let url = $(this).data('url')
            let countryId = $(this).val()
            self.getStateList(url,countryId)
        })
    }

    getStateList(url, countryId) {
        $.ajax({
            type: 'post',
            url: url,
            dataType: 'json',
            cache: false,
            crossDomain: true,
            data: {
                country_id: countryId
            },
        }).done(function (result) {

            let select = $('select[name=state_id]');
            select.find('option').remove()
            select.append('<option value="">Select</option>')

            $.each(result.results, function (index, item) {
                select.append('<option value="' + item.id + '">' + item.text + '</option>')
            })

            return true;

        }).fail(function (xhr, textStatus, errorThrown) {

            let form = $("#user_group_form")
            let errors = new formErrors(xhr.responseJSON.errors, form);
            errors.showErrors();

            return false;

        });
    }
}
