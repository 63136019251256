import $ from "jquery";
import {formErrors} from "../../../forms/formErrors";

export class CustomerAccount {

    /**
     *
     */
    constructor() {
        this.button = $('#customer-account-form .js-save-customer-account');
        this.buttonText =  this.button.html();
        this.events();
    }

    /**
     *
     */
    events() {
        let self = this;

        self.button.on('click', function () {
            self.update();
        });

        self.currencyEvent();
    }

    /**
     * Must fired only in create
     */
    currencyEvent() {

        let target = $('#customer-account-form .js-account-curency');
        let select = $('#customer-account-form select[name=shipment_currency]');
        select.change(function () {

            let value = $(this).find(':selected').attr('data-value');

            // data-value is not set in update form!
            if (typeof value != 'undefined') {
                $('#currency_value').val(value);
            }

            let sign = $(this).find(':selected').data('sign');
            target.text(sign);
        });
        target.text(select.find(':selected').data('sign'));
    }

    /**
     *
     */
    loading() {
        this.button.html(`
            <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        `);
    }

    /**
     *
     */
    update() {

        let self = this;
        self.loading();

        let url = this.button.data('url');

        let data = {
            'shipment_cost': $('#customer-account-form input[name=shipment_cost]').val(),
            'shipment_actual_desi': $('#customer-account-form input[name=shipment_actual_desi]').val(),
            'shipment_actual_cost': $('#customer-account-form input[name=shipment_actual_cost]').val(),
            'currency_value': $('#customer-account-form input[name=currency_value]').val(),
            'invoice_no': $('#customer-account-form input[name=invoice_no]').val(),
            'is_paid': $('#customer-account-form input[name=is_paid]:checked').val() ?? 0,
        };

        $.ajax({
            type: 'PUT',
            url: url,
            dataType: 'json',
            cache: false,
            crossDomain: true,
            data: data,
        }).done(function (result) {
            self.button.html(self.buttonText);
        }).fail(function (xhr) {
            let  errorList = xhr.responseJSON.errors;
            new formErrors(errorList, $('#customer-account-form'));
        });
    }
}
