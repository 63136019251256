import {FormatDataTable} from '../formatDataTable';

/**
 *
 * @param formatObj
 * @param item
 * @param data
 * @returns {[undefined,string,*,(string|*),(string|*)]}
 * @constructor
 */
export class DataTableOnLoad extends FormatDataTable {

    /**
     *
     * @param item
     * @param data
     * @returns {(*|string)[]}
     */
    constructor(item, data) {

        super()
        this.item = item;

        let self = this;
        return [
            item.id,
            item.processor,
            item.name,
            this.isActive(item.is_active),
            this.isActive(item.is_test),
            self.updateButton(data, item.id),
        ];
    }

}
