import {FormatDataTable} from '../formatDataTable';

/**
 *
 * @param formatObj
 * @param item
 * @param data
 * @returns {[undefined,string,*,(string|*),(string|*)]}
 * @constructor
 */
export class DataTableOnLoad extends FormatDataTable {

    /**
     *
     * @param item
     * @param data
     * @returns {(*|string)[]}
     */
    constructor(item, data) {

        super()
        this.item = item;

        let self = this;
        return [
            item.id,
            item.payment_name,
            item.user_id,
            item.installment,
            self.paidIcon(),
            '<b>' + item.total + '</b> TL',
            self.timeAgo(item.created_at),
        ];
    }

    /**
     *
     * @returns {string}
     */
    paidIcon() {

        let icon = []
        icon[0] = 'far fa-clock';
        icon[1] = 'fas fa-check';
        icon[2] = 'fas fa-times';

        return `
            <div class="` + this.item.status_name.toLowerCase() + ` p-1">
                <div class="row">
                <div class="col-3">
                <i class="` + icon[this.item.status] + ` pl-1"></i>
                </div>
                <div class="col-9 text-right">
                ` + this.item.status_name + `
                </div>
            </div>`;
    }
}
