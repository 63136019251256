 export class Page {

    constructor() {
        this.select2VendorEvent();
    }

    select2VendorEvent() {

        let self = this;
        let selectList = $('.js-select2-vendor')

        selectList.each(function (index, item) {
            let select = $(item);
            let url = select.data('url');
            let placeholder = select.data('placeholder');

            let options = {
                placeholder: placeholder,
                ajax: {
                    url: url,
                    delay: 250, // wait 250 milliseconds before triggering the request
                    method: 'POST',
                    dataType: 'json',
                    // Additional AJAX parameters go here; see the end of this chapter for the full code of this example
                    data: function (params) {
                        // Query parameters will be ?search=[term]&type=public
                        return {
                            search: params.term,
                            type: 'public'
                        };
                    },
                    processResults: function (data) {
                        return data;
                    },

                },
                escapeMarkup: function (markup) {
                    return markup;
                },
                templateResult: function (data) {
                    return data.html;
                },
                templateSelection: function (data) {
                    return data.text;
                }
            };

            if (typeof select.data('dropdown_parent') != 'undefined') {
                let parentSelector = select.data('dropdown_parent');
                options.dropdownParent = $(parentSelector);
            }
            select.select2(options);

            select.on('select2:select', function (e) {

            });
        });
    }
}
