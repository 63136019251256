import {FormatDataTable} from '../formatDataTable';

export class DataTableOnLoad extends FormatDataTable {

    constructor(item, data) {

        super();

        let self = this;
        let id = item.id;

        return [
            id,
            item.shelf_1,
            item.shelf_2,
            item.shelf_3,
            item.shelf_4,
            `<form action="/admin/shelve/barcode/` + id + `" class="text-center" method="POST" id="barcode` + id + `">
                <input type="hidden" name="_method" value="PATCH">
                <span class="btn btn-warning btn-circle btn-sm" onClick="document.forms['barcode` + id + `'].submit();">
                    <i class="fas fa-qrcode fa-lg"></i>
                </span>

                <input type="hidden" name="_token" value="` + $('meta[name="csrf-token"]').attr('content') + `" />

            </form>`,
            self.updateButton(data, item.id),
            self.deleteButton(item),
        ];
    }
}
