import {OnSuccess} from "../onSuccess/vendorUploadFileSuccess";

export class OnFail {

    constructor(form) {
        this.form = form
    }

    /**
     *
     * @param xhr
     */
    init(xhr) {

        this.resetErrorMessages();

        let onSuccess = new OnSuccess();
        onSuccess.resetSuccessMessages();

        if (xhr.status == 403) {
            alert('This action is unauthorized.');
            return;
        }

        let errorList = '';

        console.log(xhr.responseJSON);
        if (typeof xhr.responseJSON.message == 'string') {
            errorList = xhr.responseJSON.message;
        } else {

            $.each(xhr.responseJSON.message, function (key, errors) {
                $.each(errors, function (row, errorArr) {
                    errorList += `
                    <div>
                        <b class="text-danger"> Satır : </b> ` + key + `  <b class="text-danger">Sütun :  </b>` + row + `
                        <br>
                        <small> <b>Hatalar : </b> ` + errorArr.join(',') + `</small>
                    </div>`
                });
            });
        }
        $('.js-form-errors').removeClass('d-none').html(errorList);
    }

    /**
     *
     */
    resetErrorMessages() {
        $('.js-form-errors').addClass('d-none').html('');
    }
}
