import {Location} from './vendorOrderModal/location';

/**
 *
 */
export class FormModalOnLoad {

    /**
     *
     */
    constructor() {

        // Location
        let location = new Location('#modal_theme_primary');
        location.reset();

        this.events();
    }

    /**
     *
     */
    userSelect2() {
        let select = $('#user_id');
        let placeholder = select.data('placeholder');
        let url = select.data('url');

        let options = {
            placeholder: placeholder,
            dropdownParent: $('#modal_theme_primary'),
            minimumInputLength: 1, // only start searching when the user has input 3 or more characters
            ajax: {
                url: url,
                delay: 250, // wait 250 milliseconds before triggering the request
                method: 'post',
                dataType: 'json',
                data: function (params) {

                    return {
                        search: params,
                    };
                },
                processResults: function (data, params) {
                    let res = {
                        results: data.results
                    }

                    return res;
                }
            },
            escapeMarkup: function (markup) {
                return markup;
            },
            templateResult: function (data) {
                return data.html;
            },
            templateSelection: function (data) {
                return data.text;
            }
        }

        select.select2(options);
    }

    /**
     *
     */
    events() {

        $(document).off('click', '.js-add-phone').on('click', '.js-add-phone', function () {
            let html = $('#phone-template').html();
            $('.js-phone-container').append(html);
        });

        $(document).off('click', '.js-add-email').on('click', '.js-add-email', function () {
            let html = $('#email-template').html();
            $('.js-email-container').append(html);
        });

        /**
         * Removes both phone rows and email rows
         */
        $(document).off('click', '.js-remove').on('click', '.js-remove', function () {
            $(this).parent().parent().remove();
        });

        // Select2
        this.userSelect2();
    }

}
