import {FormatDataTable} from '../formatDataTable';

/**
 *
 */
export class DataTableOnLoad extends FormatDataTable{

    constructor(item, data) {

        super()

        let self = this;
        return [
            item.id,
            item.country_name,
            item.state_name,
            '<b>' + item.name + '</b>',
            self.yesNo(item.is_accepted),
            self.updateButton(data, item.id, 'adminLocationCity'),
            self.deleteButton(item),
        ];
    }
}
