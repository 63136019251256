import {newModal} from '../../newmodal/formModal'
import {OnFail} from "../onFail/adminUploadFileFailed";

export class OnSuccess {

    constructor(form) {
        this.form = form

        this.modal = new newModal()
    }

    init(data) {

        this.resetSuccessMessages();

        let onFail = new OnFail;
        onFail.resetErrorMessages();

        $('.js-form-success').removeClass('d-none').html(data.total + ' data kaydedildi.');
    }

    resetSuccessMessages(){
        $('.js-form-success').addClass('d-none').html('')
    }
}
